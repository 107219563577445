import service from '@/utils/request'; // @Tags UserWithdrawRecord
// @Summary 创建UserWithdrawRecord
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserWithdrawRecord true "创建UserWithdrawRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /withdraw/createUserWithdrawRecord [post]

export var createUserWithdrawRecord = function createUserWithdrawRecord(data) {
  return service({
    url: "/withdraw/createUserWithdrawRecord",
    method: 'post',
    data: data
  });
}; // @Tags UserWithdrawRecord
// @Summary 删除UserWithdrawRecord
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserWithdrawRecord true "删除UserWithdrawRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /withdraw/deleteUserWithdrawRecord [delete]

export var deleteUserWithdrawRecord = function deleteUserWithdrawRecord(data) {
  return service({
    url: "/withdraw/deleteUserWithdrawRecord",
    method: 'delete',
    data: data
  });
};
export var getCommission = function getCommission(params) {
  return service({
    url: "/withdraw/commission",
    method: 'get',
    params: params
  });
}; // @Tags UserWithdrawRecord
// @Summary 删除UserWithdrawRecord
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除UserWithdrawRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /withdraw/deleteUserWithdrawRecord [delete]

export var deleteUserWithdrawRecordByIds = function deleteUserWithdrawRecordByIds(data) {
  return service({
    url: "/withdraw/deleteUserWithdrawRecordByIds",
    method: 'delete',
    data: data
  });
}; // @Tags UserWithdrawRecord
// @Summary 更新UserWithdrawRecord
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserWithdrawRecord true "更新UserWithdrawRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /withdraw/updateUserWithdrawRecord [put]

export var updateUserWithdrawRecord = function updateUserWithdrawRecord(data) {
  return service({
    url: "/withdraw/updateUserWithdrawRecord",
    method: 'put',
    data: data
  });
}; // @Tags UserWithdrawRecord
// @Summary 用id查询UserWithdrawRecord
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserWithdrawRecord true "用id查询UserWithdrawRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /withdraw/findUserWithdrawRecord [get]

export var findUserWithdrawRecord = function findUserWithdrawRecord(params) {
  return service({
    url: "/withdraw/findUserWithdrawRecord",
    method: 'get',
    params: params
  });
}; // @Tags UserWithdrawRecord
// @Summary 分页获取UserWithdrawRecord列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取UserWithdrawRecord列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /withdraw/getUserWithdrawRecordList [get]

export var getUserWithdrawRecordList = function getUserWithdrawRecordList(params) {
  return service({
    url: "/withdraw/getUserWithdrawRecordList",
    method: 'get',
    params: params
  });
}; // @Tags UserWithdrawRecord
// @Summary 通过提现记录
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserWithdrawRecord true "通过UserWithdrawRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /userWithdrawRecord/pass [get]]

export var pass = function pass(params) {
  return service({
    url: "/withdraw/pass",
    method: "get",
    params: params
  });
}; // @Tags UserWithdrawRecord
// @Summary 提现记录决绝
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserWithdrawRecord true "拒绝UserWithdrawRecord"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /userWithdrawRecord/depass [get]]

export var depass = function depass(params) {
  return service({
    url: "/withdraw/depass",
    method: "get",
    params: params
  });
}; // @Tags UserWithdrawRecord
// @Summary 支付宝自动打款
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.UserWithdrawRecord true "支付宝自动打款"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /userWithdrawRecord/autopass [get]]

export var autopass = function autopass(params) {
  return service({
    url: "/withdraw/autopass",
    method: "get",
    params: params
  });
};